import Vue from "vue";
import Router from "vue-router";

function resource_url(otherRoutes = [], path = '', component = '') {
  const actions = ['index', 'new', 'edit', 'copy', 'delete', 'view', 'export'];

  const routes = [];
  for (var i in actions) {
    const action = actions[i];

    const uCfirst = action.charAt(0).toUpperCase() + action.substring(1);

    routes.push({
      name: action,
      path: (path ? path + '-' : '') + action + ((action !== 'index' && action !== 'new' && action !== 'export') ? '/:id' : ''),
      component: (component ? component + '/' : '') + uCfirst,
    });
  }

  return routes.concat(otherRoutes);
}

function build_routes(routes, urls, parent_name) {
  for (var i in urls) {
    let url = urls[i];

    let route = {
      path: url.path,
      name: (parent_name ? parent_name + '-' : '') + (url.name ? url.name : url.path),
      component: () => import("@/view/pages/" + url.component + ".vue")
    };

    if (typeof url.children !== "undefined" && url.children.length) {
      route.children = [];
      build_routes(route.children, url.children, route.name);
    }

    routes.push(route);
  }
}

const urls = [
  {
    path: 'enrolled-user',
    component: 'enrolled_user/EnrolledUser',
    children: resource_url([
      {
        path: 'pending-changes',
        component: 'enrolled_user/PendingChanges',
      },
    ], '', 'enrolled_user'),
  },
  {
    path: 'committee',
    component: 'committee/Committee',
    children: resource_url([
      {
        path: 'gestione/:id',
        component: 'committee/Gestione',
      },
    ], '', 'committee'),
  },
  {
    path: 'sportingclub',
    component: 'sportingclub/Sportingclub',
    children: resource_url([
      {
        path: 'export-bas',
        component: 'sportingclub/ExportBas',
      },
      {
        path: 'gestione-iscrizioni-coni',
        component: 'sportingclub/GestioneIscrizioniConi',
      },
      {
        path: 'gestione-iscrizioni-registro-sport-e-salute',
        component: 'sportingclub/GestioneIscrizioniRegistroSalute',
      },
      {
        path: 'review/:id',
        component: 'sportingclub/Review',
      },
      {
        path: 'gestione/:id',
        component: 'sportingclub/Gestione',
      },
    ], '', 'sportingclub'),
  },
  {
    path: "message",
    component: 'message/Message',
    children: resource_url([], '', 'message'),
  },
  {
    path: "document",
    component: 'document/Document',
    children: resource_url([
      {
        path: "type",
        component: 'document/type/Type',
        children: resource_url([], '', 'document/type'),
      },
      {
        path: "category",
        component: 'document/category/Category',
        children: resource_url([], '', 'document/category'),
      },
      {
        path: "tag",
        component: 'document/tag/Tag',
        children: resource_url([], '', 'document/tag'),
      },
      {
        path: "state",
        component: 'document/state/State',
        children: resource_url([], '', 'document/state'),
      },
    ], '', 'document'),
  },
  {
    path: "circular",
    component: 'circular/Circular',
    children: resource_url([
      {
        path: "type",
        component: 'circular/type/Type',
        children: resource_url([], '', 'circular/type'),
      },
      {
        path: "category",
        component: 'circular/category/Category',
        children: resource_url([], '', 'circular/category'),
      },
      {
        path: "tag",
        component: 'circular/tag/Tag',
        children: resource_url([], '', 'circular/tag'),
      },
      {
        path: "state",
        component: 'circular/state/State',
        children: resource_url([], '', 'circular/state'),
      },
    ], '', 'circular'),
  },
  {
    path: "dashboard-message",
    component: 'dashboard_message/Dashboard',
    children: resource_url([], '', 'dashboard_message'),
  },
  {
    path: "public-message",
    component: 'public_message/PublicMessage',
    children: resource_url([], '', 'public_message'),
  },
  {
    path: 'settings',
    component: 'settings/Settings',
    children: [
      {
        path: "activity",
        component: 'settings/activity/Activity',
        children: resource_url([], '', 'settings/activity'),
      },
      {
        path: "affiliation-state",
        component: 'settings/affiliation_state/AffiliationState',
        children: resource_url([], '', 'settings/affiliation_state'),
      },
      {
        path: "alert-type",
        component: 'settings/alert_type/AlertType',
        children: resource_url([], '', 'settings/alert_type'),
      },
      {
        path: "annuality",
        component: 'settings/annuality/Annuality',
        children: resource_url([], '', 'settings/annuality'),
      },
      {
        path: "annuality-type",
        component: 'settings/annuality_type/AnnualityType',
        children: resource_url([], '', 'settings/annuality_type'),
      },
      {
        path: "area-of-interest",
        component: 'settings/area_of_interest/AreaOfInterest',
        children: resource_url([], '', 'settings/area_of_interest'),
      },
      {
        path: "attachment-type",
        component: 'settings/attachment_type/AttachmentType',
        children: resource_url([], '', 'settings/attachment_type'),
      },
      {
        path: "board-operation-type",
        component: 'settings/board_operation_type/BoardOperationType',
        children: resource_url([], '', 'settings/board_operation_type'),
      },
      {
        path: "coni-city",
        component: 'settings/coni_city/ConiCity',
        children: resource_url([], '', 'settings/coni_city'),
      },
      {
        path: "committee-role-type",
        component: 'settings/committee_role_type/CommitteeRoleType',
        children: resource_url([], '', 'settings/committee_role_type'),
      },
      {
        path: "committee-type",
        component: 'settings/committee_type/CommitteeType',
        children: resource_url([], '', 'settings/committee_type'),
      },
      {
        path: "company-type",
        component: 'settings/company_type/CompanyType',
        children: resource_url([], '', 'settings/company_type'),
      },
      {
        path: "content-type",
        component: 'settings/content_type/ContentType',
        children: resource_url([], '', 'settings/content_type'),
      },
      {
        path: "credit-operation-type",
        component: 'settings/credit_operation_type/CreditOperationType',
        children: resource_url([], '', 'settings/credit_operation_type'),
      },
      {
        path: "duration-type",
        component: 'settings/duration_type/DurationType',
        children: resource_url([], '', 'settings/duration_type'),
      },
      {
        path: "enrolled-state",
        component: 'settings/enrolled_state/EnrolledState',
        children: resource_url([], '', 'settings/enrolled_state'),
      },
      {
        path: "event-frequency-type",
        component: 'settings/event_frequency_type/EventFrequencyType',
        children: resource_url([], '', 'settings/event_frequency_type'),
      },
      {
        path: "event-level",
        component: 'settings/event_level/EventLevel',
        children: resource_url([], '', 'settings/event_level'),
      },
      {
        path: "event-mode",
        component: 'settings/event_mode/EventMode',
        children: resource_url([], '', 'settings/event_mode'),
      },
      {
        path: "event-training-category-type",
        component: 'settings/event_training_category_type/EventTrainingCategoryType',
        children: resource_url([], '', 'settings/event_training_category_type'),
      },
      {
        path: "event-type",
        component: 'settings/event_type/EventType',
        children: resource_url([], '', 'settings/event_type'),
      },
      {
        path: "event-typology-type",
        component: 'settings/event_typology_type/EventTypologyType',
        children: resource_url([], '', 'settings/event_typology_type'),
      },
      {
        path: "gs-type",
        component: 'settings/gs_type/GsType',
        children: resource_url([], '', 'settings/gs_type'),
      },
      {
        path: "insurance",
        component: 'settings/insurance/Insurance',
        children: resource_url([], '', 'settings/insurance'),
      },
      {
        path: "membercard-price",
        component: 'settings/membercard_price/MembercardPrice',
        children: resource_url([], '', 'settings/membercard_price'),
      },
      {
        path: "membercard-slot-state",
        component: 'settings/membercard_slot_state/MembercardSlotState',
        children: resource_url([], '', 'settings/membercard_slot_state'),
      },
      {
        path: "membercard-type",
        component: 'settings/membercard_type/MembercardType',
        children: resource_url([], '', 'settings/membercard_type'),
      },
      {
        path: "membercard",
        component: 'settings/membercard/Membercard',
        children: resource_url([], '', 'settings/membercard'),
      },
      {
        path: "payment-type",
        component: 'settings/payment_type/PaymentType',
        children: resource_url([], '', 'settings/payment_type'),
      },
      {
        path: "president-doc-type",
        component: 'settings/president_doc_type/PresidentDocType',
        children: resource_url([], '', 'settings/president_doc_type'),
      },
      {
        path: "sodality-type",
        component: 'settings/sodality_type/SodalityType',
        children: resource_url([], '', 'settings/sodality_type'),
      },
      {
        path: "sportingclub-role-type",
        component: 'settings/sportingclub_role_type/SportingclubRoleType',
        children: resource_url([], '', 'settings/sportingclub_role_type'),
      },
      {
        path: "statute-type",
        component: 'settings/statute_type/StatuteType',
        children: resource_url([], '', 'settings/statute_type'),
      },
      {
        path: "temporary-duration-type",
        component: 'settings/temporary_duration_type/TemporaryDurationType',
        children: resource_url([], '', 'settings/temporary_duration_type'),
      },
    ],
  },
  {
    path: 'protocol',
    component: 'protocol/Protocol',
    children: resource_url([], '', 'protocol'),
  },
  {
    path: 'eps-management',
    component: 'eps/Management',
  },
  {
    path: 'eps-portal',
    component: 'eps/Gestione',
  },
  {
    path: 'eps-national-sporting-competition/:id',
    component: 'eps/national_sporting_competition/Gestione',
  },
  {
    path: 'eps-regional-sporting-competition/:id',
    component: 'eps/regional_sporting_competition/Gestione',
  },
  {
    path: 'eps-subscription/:type/:id',
    component: 'eps/subscription/Gestione',
  },
  {
    path: 'rseps-portal',
    component: 'rseps/Gestione',
  },
  {
    path: 'rseps-subscription/:type/:id',
    component: 'rseps/subscription/Gestione',
  },
  {
    path: 'requestes',
    component: 'requestes/Gestione',
  },
  {
    path: 'requestes-committees',
    component: 'requestes_committees/Gestione',
  },
  {
    path: 'security',
    component: 'security/Security',
    children: [
      {
        path: "users",
        component: 'security/users/Users',
        children: resource_url([], '', 'security/users'),
      },
    ],
  },
  {
    path: 'notifications',
    component: 'notification/Notification',
  },
];

Vue.use(Router);

const dynamic_routes = [];

build_routes(dynamic_routes, urls, '');

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Auth"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login")
      },
      {
        name: "verify",
        path: '/verify/:token',
        component: () => import("@/view/pages/auth/Verify")
      },
      {
        name: "change_pasword",
        path: '/change-password/:token',
        component: () => import("@/view/pages/auth/ChangePassword")
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/Register")
      },
      {
        name: "recover_username",
        path: "/recover-username",
        component: () => import("@/view/pages/auth/RecoverUsername")
      },
      {
        name: "recover_password",
        path: "/recover-password",
        component: () => import("@/view/pages/auth/RecoverPassword")
      }
    ]
  },
  {
    name: "membercard_verify",
    path: "/membercard/verify/:cardnumber",
    component: () => import("@/view/pages/membercardverify/Verify.vue")
  },
  {
    name: "certificate_verify",
    path: "/AcsiRest/rest/certificate/view/:idcertificate",
    component: () => import("@/view/pages/certificateverify/Verify.vue")
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    name: "404",
    path: "/404",
    component: () => import("@/view/pages/error/Error-1.vue")
  }
];

routes[0].children = routes[0].children.concat(dynamic_routes);

export default new Router({
  routes: routes,
});
