const state = {
  items: [
    { is_section: false, title: 'Dashboard', route: '/dashboard', icon: 'fas fa-home', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Gestione Magazzino', 'Comitato', 'Sodalizio'] },
    {
      is_section: false, title: 'Affiliazioni', route: '/sportingclub', icon: 'fa fa-handshake', has_children: true, has_none_sportingclubs: true, children: [
        { is_section: false, title: 'Nuovo Sodalizio', route: '/sportingclub/new', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio'], has_none_sportingclubs: true },
        { is_section: false, title: 'Ricerca Sodalizi', route: '/sportingclub/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato'] },
        { is_section: false, title: 'Gestione Iscrizioni CONI', route: '/sportingclub/gestione-iscrizioni-coni', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
        { is_section: false, title: 'Gestione Iscrizioni REGISTRO SPORT E SALUTE', route: '/sportingclub/gestione-iscrizioni-registro-sport-e-salute', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
      ], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio']
    },
    {
      is_section: false, title: 'Tesseramenti', route: '/enrolled-user', icon: 'fas fa-id-card', has_children: true, children: [
        //{ is_section: false, title: 'Nuovo Tesserato', route: '/enrolled-user/new', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato'] },
        { is_section: false, title: 'Ricerca Tesserati', route: '/enrolled-user/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato'] },
        { is_section: false, title: 'Modifiche in sospeso', route: '/enrolled-user/pending-changes', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
      ], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato']
    },

    { is_section: false, title: 'Gestione Comitato', route: '/committee/gestione/[ID_COMITATO]', icon: 'fas fa-users', has_children: false, children: [], access: ['Comitato'] },

    { is_section: false, title: 'Gestione Sodalizio', route: '/sportingclub/gestione/[ID_SODALIZIO]', icon: 'fas fa-users', has_children: false, children: [], access: ['Sodalizio'], has_single: true },
    { is_section: false, title: 'Gestione Sodalizi', route: '/sportingclub/index', icon: 'fas fa-users', has_children: false, children: [], access: ['Sodalizio'], has_single: false },

    {
      is_section: false, title: 'Comitati', route: '/committee', icon: 'fa fa-users', has_children: true, children: [
        { is_section: false, title: 'Nuovo Comitato', route: '/committee/new', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
        { is_section: false, title: 'Ricerca Comitati', route: '/committee/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
      ], access: ['Superadmin', 'Segreteria Nazionale']
    },

    { is_section: false, title: 'Certificati Attestazione APS', route: '/protocol/index', icon: 'fas fa-certificate', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

    {
      is_section: false, title: 'Gestione Inserimento Attività', route: '/eps-management', icon: 'fas fa-door-open', has_children: true, has_some_sportingclubs: true, children: [
        { is_section: false, title: 'Registro Sport e Salute', route: '/rseps-portal', icon: 'fas fa-door-open', has_children: false, has_some_sportingclubs: true, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio'] },
        { is_section: false, title: 'Archivio Portale EPS', route: '/eps-portal', icon: 'fas fa-archive', has_children: false, has_some_sportingclubs: true, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio'] },
      ], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio']
    },

    { is_section: false, title: 'Richieste Sodalizio', route: '/requestes', icon: 'fas fa-cart-arrow-down', has_children: false, has_some_sportingclubs: true, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato', 'Sodalizio'] },

    { is_section: false, title: 'Richieste Comitato', route: '/requestes-committees', icon: 'fas fa-cart-arrow-down', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale', 'Comitato'] },

    { is_section: false, title: 'Avvisi Importanti', route: '/public-message/index', icon: 'fas fa-envelope-open-text', has_children: false, has_some_sportingclubs: true, children: [], access: ['Sodalizio', 'Comitato'] },

    { is_section: false, title: 'Messaggio Dashboard', route: '/dashboard-message/index', icon: 'fas fa-envelope-open-text', has_children: false, has_some_sportingclubs: true, children: [], access: ['Superadmin'] },

    {
      is_section: false, title: 'Comunicazioni e Documenti Utili', route: '/circular', icon: 'fas fa-envelope-open-text', has_children: true, has_some_sportingclubs: true, children: [
        { is_section: false, title: 'Nuova Comunicazione', route: '/circular/new', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Ricerca Comunicazioni', route: '/circular/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Nuovo Documento', route: '/document/new', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Ricerca Documenti', route: '/document/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Tipi Comunicazione', route: '/circular/type/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Categorie Comunicazione', route: '/circular/category/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Tag Comunicazione', route: '/circular/tag/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Stati Comunicazione', route: '/circular/state/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Tipi Documento', route: '/document/type/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Categorie Documento', route: '/document/category/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Tag Documento', route: '/document/tag/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
        { is_section: false, title: 'Stati Documento', route: '/document/state/index', icon: '', has_children: false, children: [], access: ['Superadmin'] },
      ], access: ['Superadmin']
    },

    {
      is_section: false, title: 'Avvisi Importanti', route: '/message', icon: 'fas fa-envelope-open-text', has_children: true, has_some_sportingclubs: true, children: [
        { is_section: false, title: 'Nuovo Avviso Importante', route: '/message/new', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
        { is_section: false, title: 'Ricerca Avvisi Importanti', route: '/message/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
        { is_section: false, title: 'Leggi Avvisi Importanti', route: '/public-message/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
      ], access: ['Superadmin', 'Segreteria Nazionale']
    },

    {
      is_section: false, title: 'Impostazioni', route: '/settings', icon: 'fas fa-cog', has_children: true, children: [

        { is_section: false, title: 'Discipline', route: '/settings/activity/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Stati Affiliazioni', route: '/settings/affiliation-state/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Notifica', route: '/settings/alert-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Annualità', route: '/settings/annuality/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Annualità', route: '/settings/annuality-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Ambiti di attività del sodalizio', route: '/settings/area-of-interest/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipi Allegato', route: '/settings/attachment-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipi Operazione Membro Consiglio', route: '/settings/board-operation-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Città', route: '/settings/coni-city/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Ruoli Utente Comitato', route: '/settings/committee-role-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipi Comitato', route: '/settings/committee-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Società', route: '/settings/company-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Contenuti', route: '/settings/content-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Operazione Credito', route: '/settings/credit-operation-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Durata Società', route: '/settings/duration-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Stati Tesserato', route: '/settings/enrolled-state/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Frequenze Evento CONI', route: '/settings/event-frequency-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Livelli Evento CONI', route: '/settings/event-level/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Modalità Evento CONI', route: '/settings/event-mode/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Categorie Evento CONI', route: '/settings/event-training-category-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipi Evento CONI', route: '/settings/event-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Evento CONI', route: '/settings/event-typology-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Gruppo Sportivo', route: '/settings/gs-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Assicurazioni', route: '/settings/insurance/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Prezzi Tessere', route: '/settings/membercard-price/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Stati Slot Tessere', route: '/settings/membercard-slot-state/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipi Tessera', route: '/settings/membercard-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tessere Magazzino', route: '/settings/membercard/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Modalità di Pagamento', route: '/settings/payment-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipi di Documento', route: '/settings/president-doc-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Sodalizio', route: '/settings/sodality-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Ruoli Utente Sodalizio', route: '/settings/sportingclub-role-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Costituzione Società', route: '/settings/statute-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

        { is_section: false, title: 'Tipologie Durata Temporanea Società', route: '/settings/temporary-duration-type/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },

      ], access: ['Superadmin']
    },
    {
      is_section: false, title: 'Sicurezza', route: '/security', icon: 'fas fa-shield-alt', has_children: true, children: [
        { is_section: false, title: 'Utenti', route: '/security/users/index', icon: '', has_children: false, children: [], access: ['Superadmin', 'Segreteria Nazionale'] },
      ], access: ['Superadmin', 'Segreteria Nazionale']
    },
  ],
};

const getters = {
  menuItems(state) {
    return state.items;
  },
};

export default {
  state,
  getters,
};
