// Italy
export const locale = {
  TRANSLATOR: {
    SELECT: "Seleziona la tua lingua"
  },
  MENU: {
    NEW: "nuovo",
    ACTIONS: "Azioni",
    CREATE_POST: "Crea un nuovo post",
    PAGES: "Pagine",
    FEATURES: "Caratteristiche",
    APPS: "App",
    DASHBOARD: "Dashboard"
  },
  AUTH: {
    GENERAL: {
      OR: "O",
      SUBMIT_BUTTON: "Invia",
      NO_ACCOUNT: "Non hai un account?",
      SIGNUP_BUTTON: "Registrati",
      FORGOT_BUTTON: "Password dimenticata",
      BACK_BUTTON: "Indietro",
      PRIVACY: "Privacy",
      LEGAL: "Legale",
      CONTACT: "Contattaci"
    },
    LOGIN: {
      TITLE: "Account di Login",
      BUTTON: "Effettua il login"
    },
    FORGOT: {
      TITLE: "Password Dimenticata?",
      DESC: "Inserisci la tua e-mail per ripristinare la tua password",
      SUCCESS: "Il tuo account è stato correttamente ripristinato."
    },
    REGISTER: {
      TITLE: "Registrati",
      DESC: "Inserisci i tuoi dettagli per creare un account",
      SUCCESS: "Il tuo account è stato correttamente registrato."
    },
    INPUT: {
      EMAIL: "E-mail",
      FULLNAME: "Nome completo",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Conferma Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} non è valido",
      REQUIRED: "{{name}} è obbligatorio",
      MIN_LENGTH: "{{name}} ha una lunghezza minima di {{min}} caratteri",
      AGREEMENT_REQUIRED: "L'accettazione dei termini e delle condizioni è obbligatoria",
      NOT_FOUND: "Il {{name}} richiesto non è stato trovato",
      INVALID_LOGIN: "Il dettaglio del login fornito non è corretto",
      REQUIRED_FIELD: "Campo obbligatorio",
      MIN_LENGTH_FIELD: "Lunghezza minima campo:",
      MAX_LENGTH_FIELD: "Lunghezza massima campo:",
      INVALID_FIELD: "Il campo non è valido"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Numero di record selezionati: ",
      ALL: "Tutti",
      SUSPENDED: "Sospesi",
      ACTIVE: "Attivi",
      FILTER: "Filtri",
      BY_STATUS: "per Stato",
      BY_TYPE: "per Tipo",
      BUSINESS: "Business",
      INDIVIDUAL: "Individuale",
      SEARCH: "Ricerca",
      IN_ALL_FIELDS: "in tutti i campi"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Clienti",
      CUSTOMERS_LIST: "lista Clienti",
      NEW_CUSTOMER: "Nuovo Cliente",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Elimina Cliente",
        DESCRIPTION: "Sei sicuro di voler eliminare definitivamente questo cliente?",
        WAIT_DESCRIPTION: "Eliminazione del cliente in corso...",
        MESSAGE: "Il Cliente è stato eliminato"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Clienti Eliminati",
        DESCRIPTION: "Sei sicuro di voler eliminare definitivamente questi clienti?",
        WAIT_DESCRIPTION: "Eliminazione dei clienti in corso...",
        MESSAGE: "I Clienti Selezionati sono stati eliminati"
      },
      UPDATE_STATUS: {
        TITLE: "Lo Stato è stato aggiorato per i clienti selezionati",
        MESSAGE: "I Clienti Selezionati sono stati correttamente aggiornati"
      },
      EDIT: {
        UPDATE_MESSAGE: "I Clienti sono stati aggiornati",
        ADD_MESSAGE: "Il Cliente è stato creato"
      }
    }
  }
};
