import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import store from "@/core/services/store";
import { FORCE_LOGOUT } from "@/core/services/store/auth.module";
import router from "../../router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  getApiUrl() {
    return Vue.axios.defaults.baseURL;
  },

  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

    // Add a response interceptor
    Vue.axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (typeof error.response === "undefined" || error.response.status === 401) {
        localStorage.removeItem('currentSportingClubId');
        localStorage.removeItem('currentCommitteeId');
        localStorage.removeItem('currentAnnualityId');

        store
          .dispatch(FORCE_LOGOUT)
          .then(() => router.push({ path: "/login" }).catch(() => {}));
      }

      if (typeof error.response === "force notice" || error.response.status === 406) {
        if (router.currentRoute.path !== '/dashboard') {
          router.push({ path: "/dashboard" });
        }
      }

      return Promise.reject(error);
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  /**
   * Set the Token in the HTTP Header if we got one
   */
  setToken() {
    if (JwtService.getToken()) {
      this.setHeader();
    }
  },

  query(resource, params) {
    //this.setToken();

    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    //this.setToken();

    return Vue.axios.get(resource).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    //this.setToken();

    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    //this.setToken();

    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    //this.setToken();

    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    //this.setToken();

    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
