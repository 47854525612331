import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const FORCE_LOGOUT = "forceLogout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  notifications: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentNotifications(state) {
    return state.notifications;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("/auth/login", credentials)
                .then(({ data }) => {
                  context.commit(SET_AUTH, data);
                  resolve(data);
                })
                .catch(({ response }) => {
                  if (typeof response !== "undefined") context.commit(SET_ERROR, response.data.errors);
                });
    });
  },
  [LOGOUT](context) {
    if (JwtService.getToken()) {
      ApiService.setToken();
      ApiService.post("/auth/logout")
                .then(() => {
                  context.commit(PURGE_AUTH);
                })
                .catch(({ response }) => {
                  context.commit(SET_ERROR, response.data.errors);
                  context.commit(PURGE_AUTH);
                });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [FORCE_LOGOUT](context) {
      context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/register", credentials)
                .then(({ data }) => {
                  //context.commit(SET_AUTH, data);
                  resolve(data);
                })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  async [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      try {
        ApiService.setToken();
        let response = await ApiService.post("/auth/refresh");
        if (response.status !== 200)
        {
          context.commit(PURGE_AUTH);
        }
        else
        {
          context.commit(SET_AUTH, response.data);
        }
      }
      catch(err) {
        context.commit(SET_ERROR, err);
        context.commit(PURGE_AUTH);
      }
    }
    else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    ApiService.setToken();
    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.errors = {};

    data.user.token = data.access_token;
    state.user = data.user;
    state.notifications = data.notifications;

    JwtService.saveToken(state.user.token);
    ApiService.setHeader();
  },
  [PURGE_AUTH](state) {
    localStorage.removeItem('currentSportingClubId');
    localStorage.removeItem('currentCommitteeId');
    localStorage.removeItem('currentAnnualityId');
    localStorage.removeItem('currentEpsTabIndex');
    localStorage.removeItem('currentEpsIdannuality');
    state.isAuthenticated = false;
    state.user = {};
    state.notifications = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
